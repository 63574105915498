import React, { useState } from 'react';

function BlogCard({ pic, name, role, intro, badge }) {
    const [showFullText, setShowFullText] = useState(true);

    const toggleReadMore = () => {
        setShowFullText(!showFullText);
    };

    return (
        <div className="profile-card">
            <div style={{ display: "flex" }}>
                <img className="profile-image" src={pic} alt={name} />
                <img src={badge} className='child' alt="" width={80} />
            </div>
            <div className="profile-details">
                <div className="profile-name">{name}</div>
                <div className='profile-p'>
                    <p style={{ color: "#e92c58" }}>{role}</p>
                    <p className={showFullText ? 'full-text' : ''}>{intro}</p>
                    <button onClick={toggleReadMore}>
                        {showFullText ? 'Read More' : 'Read Less'}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default BlogCard;
