import React from 'react'
import blog1 from "../images/blog1.jpeg"
import blog2 from "../images/blog2.jpeg"
import dummyPic from "../images/V2.png"
const Home = () => {
    return (
        <div className="main-panel">
            <div className="container-fluid mt-5">
                <div style={{ backgroundColor: '#f2f2f2', borderRadius: '10px', padding: '10px' }}>
                    <h2 style={{ color: '#333' }}>Explore Our Services:</h2>
                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                        <li style={{ margin: '10px 0', fontSize: '18px', color: '#007acc' }}>🌐 Pentesting IoT</li>
                        <li style={{ margin: '10px 0', fontSize: '18px', color: '#007acc' }}>🤖 AI Powered Offensive Tools</li>
                        <li style={{ margin: '10px 0', fontSize: '18px', color: '#007acc' }}>🎣 Tailored Phishing Pages</li>
                        <li style={{ margin: '10px 0', fontSize: '18px', color: '#007acc' }}>🔮 Quantum Research (Coming Soon)</li>
                    </ul>
                </div>

                <div className="row">

                    <div className="col-md-6 col-lg-4 ">
                        <div className=" new-bootstrap-card">
                            <img src={dummyPic} alt="" className="card-img-top" />
                            <div className="card-body">
                                <p className="card-text">HackEdge device, get your own offensive tool, utilizing AI to simplify the process and find vulnerabilities in your area, with your selected protocol. It has two goals: Compromise or shutdown</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4">

                        <div className=" new-bootstrap-card">
                            <img src={dummyPic} alt="" className="card-img-top" />
                            <div className="card-body">
                                <p className="card-text">IoT Pentesting, test your device from being compromised after production, with an indepth analysis of the hardware, firmware and the communication protocols</p>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    )
}

export default Home