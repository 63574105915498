import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { contact } from '../store/featureActions'

const Contact = () => {

    const [name, setname] = useState(null)
    const [email, setemail] = useState('')
    const [message, setmessage] = useState('')
    const dispatch = useDispatch()
    const submitCategory = async (e) => {
        e.preventDefault()
        try {
            let payload = {
                body: { email, name, message },
                params: false,
                isToast: true
            }
            await dispatch(contact(payload)).unwrap()
            setname("")
            setemail("")
            setmessage("")
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError)
        }

    }
    return (
        <div>
            <div className="main-panel">
                <div className="container mt-5">
                    <section id="contact">
                        <h1 className="section-header">Contact</h1>
                        <div className="contact-wrapper">
                            {/* Left contact page */}
                            <form id="contact-form" className="form-horizontal" role="form">
                                <div className="form-group">
                                    <div className="inputField col-sm-12">
                                        <input type="text" className="form-control" id="name" placeholder="NAME" name="name" value={name} onChange={(e) => setname(e.target.value)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="inputField col-sm-12">
                                        <input type="email" className="form-control" id="email" placeholder="EMAIL" name="email" value={email} onChange={(e) => setemail(e.target.value)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="inputField col-sm-12">
                                        <textarea className="form-control" rows={10} placeholder="MESSAGE" name="message" value={message} onChange={(e) => setmessage(e.target.value)} />
                                    </div>
                                </div>
                                <button className="btn btn-primary send-button" onClick={(e) => submitCategory(e)} id="submit" type="submit" value="SEND">
                                    <div className="alt-send-button">
                                        <i className="fa fa-paper-plane" /><span className="send-text">SEND</span>
                                    </div>
                                </button>
                            </form>
                            {/* Left contact page */}
                            <div className="direct-contact-container">
                                <ul className="contact-list">
                                    <li className="list-item"><i className="fa fa-map-marker fa-2x"><span className="contact-text place">Trondheim, Norway</span></i></li>
                                    <li className="list-item"><i className="fa fa-phone fa-2x"><span className="contact-text phone"><a href="tel:(+47) 919 11 770" title="Give me a call">(+47) 919 11 770</a></span></i></li>
                                    {/* <li className="list-item"><i className="fa fa-envelope fa-2x"><span className="contact-text gmail"><a href="mailto:#" title="Send me an email">hitmeup@gmail.com</a></span></i></li> */}
                                </ul>
                                <hr />

                                <hr />
                                <div className="copyright">© ALL OF THE RIGHTS RESERVED</div>
                            </div>
                        </div>
                    </section>



                </div>
            </div>
        </div>
    )
}

export default Contact