import React from 'react'
import blog1 from "../images/blog1.jpeg"
import blog2 from "../images/blog2.jpeg"
import { useNavigate } from "react-router-dom"
import { getAllBlogs } from '../store/featureActions'
// import dummyPic from "../images/V2.png"
import { useState, useEffect } from "react"
import $ from "jquery"
import pic from "../images/demoblog.jpg"
import { useDispatch } from 'react-redux';
const Blogs = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [data, setdata] = useState(null)

  const blogList = async () => {
    try {
      setdata(null)
      let payload = {
        params: false,
        isToast: false
      }
      const response = await dispatch(getAllBlogs(payload)).unwrap()
      console.log(response?.data?.data)
      setdata(response?.data?.data)
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError)
    }
  }
  useEffect(() => {
    let mount = true
    if (mount) {
      blogList();
    }
    return () => {
      mount = false
    }
  }, [])




  return (


    <div className="main-panel">
      <div className="container mt-5">
        <div style={{ backgroundColor: '#f2f2f2', borderRadius: '10px', padding: '10px' }}>
          <h2 style={{ color: '#333' }}>Our Blogs:</h2>
        </div>
        <div className="row mt-5">
          {
            data?.map((myData, index) => (

              <div className="col-lg-6 col-md-6 col-12" key={index}>
                <div className="news-card">
                  <figure className="news-card__thumb">
                    <img src={`${myData?.image}`} alt="Picture by David Monje" className="news-card__image" style={{ maxWidth: '100%', height: 'auto' }} />

                    <div className="news-card__caption">
                      <h2 className="news-card__title">{myData?.title}</h2>
                      <div dangerouslySetInnerHTML={{ __html: `${myData?.detail.substring(0, 200)}...` }}></div>
                      <button onClick={() => navigate("/blog-detail", { state: myData })} className="news-card__button">Read more</button>
                    </div>
                  </figure>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default Blogs