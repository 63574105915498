import React from 'react'
import pic from "../images/blog1.jpeg"
import pic2 from "../images/blog2.jpeg"
import { useNavigate } from "react-router-dom"
import { getAllBlogs, getAllDevice } from '../store/featureActions'
import logo from "../images/logo.png"
import { useState, useEffect } from "react"
import $ from "jquery"
import { useDispatch } from 'react-redux';

const Devices = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [data, setdata] = useState(null)
  const deviceList = async () => {
    try {
      setdata(null)
      let payload = {
        params: false,
        isToast: false
      }
      const response = await dispatch(getAllDevice(payload)).unwrap()

      setdata(response?.data?.data)
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError)
    }
  }
  useEffect(() => {
    let mount = true
    if (mount) {
      deviceList();
    }
    return () => {
      mount = false
    }
  }, [])

  return (
    <div className="main-panel">
      <div className="container-fluid mt-5">
        <div style={{ backgroundColor: '#f2f2f2', borderRadius: '10px', padding: '10px' }}>
          <h2 style={{ color: '#333' }}>HackEdge Devices – AI enabled offensive devices</h2>
        </div>

        <div className='HackEdge_container'>
          <p className="paragraph">Offensive IoT tools, utilizing EdgeAI and Cloud-powered AI, with main program running in Rust for speed, efficiency and robustness</p>
          <p className="paragraph">HackEdge is a framework, combining famous tools, along with some self-written ones</p>
          <p className="paragraph">Choose between a specialized device or a general device</p>
          <p className="paragraph">Designed to be easy to use, anyone with zero knowledge in coding, hacking or computers in general can utilize these tools</p>
          <div style={{ margin: "15px 0" }}>
            <div className='device-page-logo'>
              <img src={logo} alt="" />
            </div>
            <div>
              <h4>Utilizing both EdgeAI and CloudAI
              </h4>
            </div>
            <div>
              <ol>
                <li>Scan for your preferred service, such as MQTT, FTP, SSH and many more, or communication protocols like Bluetooth and ZigBee</li>
                <li>Get notified when certain service is available nearby, along with it's IP address</li>
                <li>Enter the IP, and wait for magic to happen, with the help of AI it will find a suitable attack and perform it if the user accepts it</li>
              </ol>
            </div>
          </div>
          <div className="row mt-5">
            {
              data?.map((myData, index) => (

                <div className="col-lg-6 col-md-6 col-12" key={index}>
                  <div className="news-card">
                    <figure className="news-card__thumb">
                      <img src={`${myData?.image}`} alt="Picture by David Monje" className="news-card__image" style={{ maxWidth: '100%', height: 'auto' }} />

                      <div className="news-card__caption">
                        <h2 className="news-card__title">{myData?.title}</h2>
                        <div dangerouslySetInnerHTML={{ __html: `${myData?.detail.substring(0, 200)}...` }}></div>
                        <button onClick={() => navigate("/hackage-devices-detail", { state: myData })} className="news-card__button">Read more</button>
                      </div>
                    </figure>
                  </div>
                </div>
              ))
            }


          </div>
        </div>


      </div >
    </div >





  )
}

export default Devices