import { deleteRequest, getRequest, postRequest } from '../apiHelper';

//POST REQUEST
export const updatePassword = postRequest('admins/changepassword', 'changepassword');
export const signinUser = postRequest('admins/signin/', 'signin');
export const userLogout = postRequest('admins/signout/', 'signout');
export const addFished = postRequest('admins/addFishEd/', 'addFishEd');
export const addSpecies = postRequest('admins/addSpecies/', 'addSpecies');
export const createRoaster = postRequest('admins/addRoasterOrMachine/', 'addRoasterOrMachine');
export const editTheRoaster = postRequest('admins/editRoasterOrMachine/', 'editRoasterOrMachine');
export const editSpecie = postRequest('admins/editSpecie/', 'editSpecie');
export const addPointTable = postRequest('admins/addPointTable/', 'addPointTable');
export const editPoints = postRequest('admins/editPoints/', 'editPoints');
export const addLake = postRequest('admins/addLake/', 'addLake');
export const editLake = postRequest('admins/editLake/', 'editLake');
export const contact = postRequest('/send-contact', 'send-contact');
export const updateTcpp = postRequest('admins/TcPp/', 'content');

//GET REQUEST
export const dashboard = getRequest('admins/dashboard/', 'dashboard');
export const getFished = getRequest('admins/getFished/', 'getFished');
export const getAllBlogs = getRequest('/list-of-blogs', 'list-of-blogs');
export const getAllDevice = getRequest('/list-of-device', 'list-of-device');
export const recentLakes = getRequest('admins/recentLakes/', 'recentLakes');
export const getAllUsers = getRequest('admins/getAllUsers/', 'getAllUsers');
export const getAllVendors = getRequest('admins/getAllVendors/', 'getAllVendors');
export const getAllCafe = getRequest('admins/getAllCafe/', 'getAllCafe');
export const listOfCafe = getRequest('admins/listOfCafe/', 'listOfCafe');
export const getAllRoaster = getRequest('/admins/roasters/', 'roasters');
export const getAllMachine = getRequest('/admins/machines/', 'machines');
export const getAllOrders = getRequest('/admins/getOrders/', 'getOrders');
export const getSpecies = getRequest('admins/getSpecies/', 'getSpecies');
export const getSpeciePoints = getRequest('admins/getSpeciePoints/', 'getSpeciePoints');
export const getAllLakes = getRequest('admins/getLakes/', 'getLakes');
export const blockUnblock = getRequest('admins/blockUnblock/', 'blockUnblock');
export const TcPp = getRequest('admins/getTcPp/', 'getTcPp');

//DELETE REQUEST
export const deleteFished = deleteRequest('admins/deleteFished/', 'deleteFished');
export const deleteSpecie = deleteRequest('admins/deleteSpecie/', 'deleteSpecie');
export const deletePoints = deleteRequest('admins/deletePoints/', 'deletePoints');
export const deleteLake = deleteRequest('admins/deleteLake/', 'deleteLake');
export const deleteAccount = deleteRequest('admins/deleteAccount/', 'deleteAccount');
export const deleteRoaster = deleteRequest('admins/deleteRoasterOrMachine/', 'deleteRoasterOrMachine');

