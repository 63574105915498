import React from 'react'
import blog1 from "../images/blog1.jpeg"
import blog2 from "../images/blog2.jpeg"
import dummyPic from "../images/V2.png"
const Phishing = () => {
  return (
    <div>
      <div className="main-panel">
        <div className="container-fluid mt-5">
          <div style={{ backgroundColor: '#f2f2f2', borderRadius: '10px', padding: '10px' }}>
            <h2 style={{ color: '#333' }}>WhiteHat Phishing:</h2>

          </div>
          <div className="row" style={{ margin: "20px 0" }}>
            <div className="col-md-6 col-lg-4 ">
              <div className=" new-bootstrap-card">
                <img src={dummyPic} alt="" className="card-img-top" />
                <div className="card-body">
                  <p className="card-text">Customized Web Page Phishing</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 ">

              <div className="new-bootstrap-card">
                <img src={dummyPic} alt="" className="card-img-top" />
                <div className="card-body">
                  <p className="card-text">Test Your Strength Today</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Phishing