import React from 'react'
import pic1 from "../images/hamza.jpeg"
import pic2 from "../images/thesis.jpeg"
import badge1 from "../images/badge.png"
import badge2 from "../images/badge2.png"
import BlogCard from './BlogCard'

const About = () => {
  return (
    <div className="main-panel">
      <div className="container mt-5">
        <div style={{ backgroundColor: '#f2f2f2', borderRadius: '10px', padding: '10px' }}>
          <h2 style={{ color: '#333' }}>About</h2>
        </div>
        <div className='container' style={{ margin: "10px 0" }}>

          <p>HackEdge is currently a non-profit hobby project started by the curiosity of combining the skillset of Penetration Testing, Coding and AI/ML</p>
          <p>The focus is to test the limits of AI/ML, combining both EdgeAI and Cloud-powered AI together with fast and efficient code languages to create cutting edge IoT offensive devices.</p>
          <p>Focused on being easy to use, rather than comprehensive and detailed.</p>
          <p > HackEdge mission is to create small devices specialized to automatically and easily find and attempt penetrating authorized systems. It does use several open-source tools and boards, example like Raspberry. HackEdge have no cooperation with any third party, and our task is to create the framework for enhancing the efficiency, rewriting tools, along with implementing AI and Machine Learning technologies.</p>
        </div>

        <div>

          <div className="profile">

            {/* <div className="">
              <div className="profile-card">
                <img className="profile-image" src={pic2} alt="Your Name" />
                <div className="profile-details">
                  <div className="profile-name">Theis</div>
                  <div className='profile-p'>

                    <p style={{ color: "#e92c58" }}>IoT Pentester and Software Developer</p>
                    <p className=''>Im Theis, I am a certified IoT Pentester and a software developer, specialized on developing offensive tools utilizing AI and Machine Learning. I write mainly in Rust, C and Python, but have a general understanding of others, example ARM Assembly. Some experience in Qiskit framework for Quantum programming.</p>
                  </div>

                </div>
              </div>
            </div>

            <div className="">
              <div className="profile-card">
                <img className="profile-image" src={pic1} alt="Hamza" />
                <div className="profile-details">
                  <div className="profile-name">Hamza</div>
                  <div className='profile-p'>
                    <p style={{ color: "#e92c58" }}>Web Developer</p>
                    <p className=''>Hello! I'm Hamza, but you might also know me as Vincent. With a knack for blending creativity and technology, I've dived deep into the world of web and software development. My expertise revolves around the MERN stack (MongoDB, Express.js, React, Node.js) among other modern technologies. Passionate about building efficient, user-friendly, and innovative digital solutions, I strive to remain at the forefront of tech trends, ensuring that the products I develop aren't just functional but also ahead of the curve.</p>
                  </div>
                </div>
              </div>
            </div> */}


            <BlogCard
              pic={pic2}
              badge={badge1}
              name="Theis Lien"
              role="Penetration Tester And Software Developer"
              intro="Im Theis, I am a certified IoT Pentester and a software developer, specialized on developing offensive tools utilizing AI and Machine Learning. I write mainly in Rust, C and Python, but have a general understanding of others, example ARM Assembly."
            />

            <BlogCard
              pic={pic1}
              badge={badge2}
              name="Hamza"
              role="Web Developer"
              intro="Hello! I'm Hamza, but you might also know me as Vincent. With a knack for blending creativity and technology, I've dived deep into the world of web and software development. My expertise revolves around the MERN stack (MongoDB, Express.js, React, Node.js) among other modern technologies. Passionate about building efficient, user-friendly, and innovative digital solutions, I strive to remain at the forefront of tech trends, ensuring that the products I develop aren't just functional but also ahead of the curve."
            />

          </div>


        </div>


      </div>
    </div>
  )
}





export default About