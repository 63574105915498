import React from 'react'
import pic from "../images/blog1.jpeg"
import { useLocation } from "react-router-dom"
const DeviceDetail = () => {
    const location = useLocation()
    console.log(location?.state)
    return (
        <div>
            <div className="main-panel">
                <div className="container mt-5">
                    <main >
                        <div className='blog_desc'  >
                            <div className='blog-detail-img' >
                                <img src={`${location?.state?.image}`} alt="Picture by David Monje" className="news-card__image" style={{ maxWidth: '100%', height: 'auto' }} />

                            </div>
                            <div className="blog-content">
                                <h2>{location?.state?.title}</h2>
                                <div className='HackEdge_list' dangerouslySetInnerHTML={{ __html: location?.state?.detail }}></div>

                            </div>
                        </div>
                    </main>

                </div>
            </div>
        </div>
    )
}

export default DeviceDetail