import React from 'react'
import blog1 from "../images/blog1.jpeg"
import blog2 from "../images/blog2.jpeg"
import dummyPic from "../images/V2.png"
const Pentesting = () => {
  return (
    <div>
      <div className="main-panel">
        <div className="container  mt-5">
          <div style={{ backgroundColor: '#f2f2f2', borderRadius: '10px', padding: '10px' }}>
            <h2 style={{ color: '#333' }}>IoT Pentesting:</h2>
          </div>
          <div className='listing_style' style={{ margin: "20px 0" }}>
            <h4> NO CURE, NO PAY</h4>
            <ul>
              <li>Certified IoT Pentester Theis Lien, performs an indepth analysis of your specific device.</li>
              <li>Analysis firmware, hardware, mobile/web app and communication protocols.</li>
            </ul>
          </div>

          <div className="row">

            <div className="col-md-6 col-lg-4">
              <div className=" new-bootstrap-card">
                <img src={dummyPic} alt="" className="card-img-top" />
                <div className="card-body">
                  <p className="card-text">Firmware analysis, among other techniques</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">

              <div className=" new-bootstrap-card">
                <img src={dummyPic} alt="" className="card-img-top" />
                <div className="card-body">
                  <p className="card-text">Communication analysis, such as JTAG/SWD, wired and wireless such as Bluetooth and more</p>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  )
}

export default Pentesting