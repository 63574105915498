import React from 'react'
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Dashboard } from './SidebarIcons/Dashboard';
import { User } from './SidebarIcons/User';
import { AllUsers } from './SidebarIcons/AllUsers';
import { Link, useLocation } from 'react-router-dom'
import { Content } from './SidebarIcons/Content';
import { TermsAndConditions } from './SidebarIcons/TermsAndConditions';
import { PrivacyPolicy } from './SidebarIcons/PrivacyPolicy';
import image from "./SidebarIcons/launch_screen.png"
import logo from "../../images/logo.png"

const SidebarPro = () => {
    const location = useLocation()
    const active = {
        dashboard: location?.pathname == "/dashboard",

        userManagement: location?.pathname == "/user-list",
        userList: location?.pathname == "/user-list",
        vendorList: location?.pathname == "/vendor-list",

        equipmentManagement: location?.pathname == "/roaster-list",
        roasterList: location?.pathname == "/roaster-list",
        machineList: location?.pathname == "/machine-list",

        orderManagement: location?.pathname == "/list-of-cafe",
        orderList: location?.pathname == "/list-of-cafe",


        fishEdManagement: location?.pathname == "/fish-ed",
        fishEd: location?.pathname == "/fish-ed",

        specieManagement: location?.pathname == "/specie-list" || location.pathname == "/specie-points",
        allSpecies: location?.pathname == "/specie-list" || location.pathname == "/specie-points",

        lakesManagement: location?.pathname == "/lake-list",
        allLakes: location?.pathname == "/lake-list",

        contentManagement: location?.pathname == "/terms-and-conditions" || location.pathname == "/privacy-policy" || location.pathname == "/about-us",
        termsAndConditions: location?.pathname == "/terms-and-conditions",
        privacyPolicy: location?.pathname == "/privacy-policy",
        aboutUs: location?.pathname == "/about-us"
    }
    return (
        <div style={{ display: 'flex', height: '100wh', minHeight: '100vh', backgroundSize: "cover !important" }}>
            <Sidebar image={image} rtl={false}
                breakPoint="lg"
                backgroundColor="#f4f4f4"
                rootStyles={{
                    color: "#8ba1b7",
                }}>
                <div className="sidebar-header">
                    <div className="logo text-center">
                        <Link to="/"><img src={logo} style={{ width: "50%", borderRadius: 10 }} alt="logo" className="img-fluid my-3" /></Link>
                    </div>
                </div>
                <Menu menuItemStyles={{
                    root: {
                        fontSize: '13px',
                        fontWeight: 400,
                    },
                    button: ({ level, active, disabled, open }) => {
                        if (level === 0)
                            return {
                                color: disabled ? '#777777' : '#8ba1b7',
                                fontWeight: active ? '600' : "normal",
                                backgroundColor: active ? "#e92c58" : undefined,
                                color: open ? "#b6c8d9" : undefined,
                                '&:hover': {
                                    backgroundColor: "#e0d9d9",
                                    color: "black",
                                },
                            };
                    },
                    label: ({ open, active }) => ({
                        fontWeight: open || active ? 600 : undefined,
                    }),
                }} transitionDuration={500} >
                    <Link to="/" style={{ paddingLeft: 0, color: 'black' }} >
                        <MenuItem icon={<Dashboard />} className={location?.pathname === "/" ? "side-active" : "side-noactive"}> Home</MenuItem>
                    </Link>
                    <Link to="/hackage-devices" style={{ paddingLeft: 0, color: 'black' }} >
                        <MenuItem icon={<Dashboard />} className={location?.pathname === "/hackage-devices" ? "side-active" : "side-noactive"}>HackEdge Devices</MenuItem>
                    </Link>
                    <Link to="/iot-pentesting" style={{ paddingLeft: 0, color: 'black' }} >
                        <MenuItem icon={<Dashboard />} className={location?.pathname === "/iot-pentesting" ? "side-active" : "side-noactive"}>IoT Pentesting</MenuItem>
                    </Link>
                    <Link to="/whitehat-phishing" style={{ paddingLeft: 0, color: 'black' }} >
                        <MenuItem icon={<Dashboard />} className={location?.pathname === "/whitehat-phishing" ? "side-active" : "side-noactive"}>WhiteHat Phishing</MenuItem>
                    </Link>
                    <Link to="/blogs" style={{ paddingLeft: 0, color: 'black' }} >
                        <MenuItem icon={<Dashboard />} className={location?.pathname === "/blogs" ? "side-active" : "side-noactive"}>Blogs</MenuItem>
                    </Link>
                    <Link to="/about-us" style={{ paddingLeft: 0, color: 'black' }} >
                        <MenuItem icon={<Dashboard />} className={location?.pathname === "/about-us" ? "side-active" : "side-noactive"}>About</MenuItem>
                    </Link>
                    <Link to="/contact-us" style={{ paddingLeft: 0, color: 'black' }} >
                        <MenuItem icon={<Dashboard />} className={location?.pathname === "/contact-us" ? "side-active" : "side-noactive"}>Contact</MenuItem>
                    </Link>






                </Menu>
            </Sidebar>
        </div>
    )
}

export default SidebarPro