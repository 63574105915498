import React, { useContext } from 'react'
import Nav from './Nav'
import SidebarPro from './SidebarPro'
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { getUserError, getUserStatus, getUserToken, sessionOut } from "../../store/slices/userSlice"
import Home from '../../pages/Home';
import Devices from '../../pages/Devices';
import Pentesting from '../../pages/Pentesting';
import Phishing from '../../pages/Phishing';
import Blogs from '../../pages/Blogs';
import About from '../../pages/About';
import Contact from '../../pages/Contact';

import { context } from '../../context/context';
import BlogDetail from '../../pages/BlogDetail';
import DeviceDetail from '../../pages/DeviceDetail';


const Layout = () => {
    const { isLoading } = useContext(context);
    const status = useSelector(getUserStatus)
    const authToken = useSelector(getUserToken)
    const error = useSelector(getUserError)
    const dispatch = useDispatch()
    if (error == 401) {
        dispatch(sessionOut())
        localStorage.clear();
    }

    return (
        <>

            <div className={"wrapper"}>
                <BrowserRouter>

                    <SidebarPro />
                    <div style={{ width: "100%" }}>
                        <Nav />
                        <Routes>
                            <Route path="*" element={<Navigate to="/" />} />
                            <Route path="/" exact element={<Home />} />
                            <Route path="/hackage-devices" exact element={<Devices />} />
                            <Route path="/hackage-devices-detail" exact element={<DeviceDetail />} />
                            <Route path="/iot-pentesting" exact element={<Pentesting />} />
                            <Route path="/whitehat-phishing" exact element={<Phishing />} />
                            <Route path="/blogs" exact element={<Blogs />} />
                            <Route path="/blog-detail" exact element={<BlogDetail />} />
                            <Route path="/about-us" exact element={<About />} />
                            <Route path="/contact-us" exact element={<Contact />} />

                        </Routes>
                    </div>


                </BrowserRouter>
            </div>
        </>
    )
}

export default Layout